import React, { Component } from 'react';
import Article from '../article';
import { Helmet } from 'react-helmet';

class Mitgliederbrief extends Component {
    state = {}
    render() {
        return (
            <>
                <Helmet>
                    <title>Mitgliederbrief - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Wir informieren rund ums Herz', veröffentlicht am Mittwoch, 10.01.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel informiert über die Bemühungen der Herzinitiative, die Gesundheitskompetenz in Wuppertal im Bereich der Herz- und Kreislaufmedizin auszubauen. Erfahren Sie, wie die Initiative neue Wege geht, um das Angebot an Gesundheitsinformationen auszubauen, Kooperationen im Gesundheitswesen einzugehen und die Medienpräsenz zu erhöhen." />
                </Helmet>

                <Article
                    title={"Mitgliederbrief"}
                    date={"Mittwoch, 15.01.2025"}
                    tag1={"Arbeit"}
                    tag2={"Erfolge"}
                    sections={
                        [
                            "Sehr geehrte Mitglieder der Wuppertaler Herzinitiative e.V.,",
                            "zum neuen Jahr sagen wir Ihnen ein herzliches Dankeschön – Sie haben mit Ihrer Mitgliedschaft, Ihren Beiträgen und auch durch Ihr Engagement die Bemühungen unserer Initiative um mehr Herzgesundheit in Wuppertal spürbar unterstützt.",
                            "Mit Ihrer Hilfe konnte und kann unser Verein wichtige Projekte auf den Weg bringen und vorantreiben. Wir hoffen, dass diese Beiträge zu einer Optimierung der Versorgung führen und herzkranke Wuppertalerinnen und Wuppertaler davon profitieren: ",
                            "- Im Jahre 2024 konnten wir das von uns gestartete und aktiv vorangetriebene Projekt „Mobile Herzretter im Tal“ vereinbarungsgemäß in die Federführung durch die Stadt Wuppertal übertragen. In der Übergangsvereinbarung wird die mit sachlichen und finanziellen Mitteln sowie Know-how von der Herzinitiative geleistete „wertvolle Starthilfe“ gelobt und bestätigt, dass das System im Echtbetrieb funktioniert und etabliert ist. Mehr als 500 Herzretterinnen und Herzretter haben in mehr als 1000 ehrenamtlichen Einsätzen wichtige Hilfe geleistet. Besonderen Dank an die Erstretterinnen und Erstretter. Und wir haben die steuernde Begleitung und intensive Förderung der Sache durch unser Mitglied, Frau Nadine Pätzold, natürlich noch in lebhafter Erinnerung.Nadine Pätzold gebührt besonderer Dank!",
                            "- In diesem Jahr widmen wir unsere Arbeit primär dem Kampf gegen die herzkrank machenden Risikofaktoren – im ersten Schritt dem Übergewicht und dem Bewegungsmangel. Wir werden 2025 mit unserer „Konzertierten Herzgesundheitsaktion Wuppertal“ alles tun, um Bewegung und Sport in unserer Stadt stärker zu fördern und für eine herzgesündere Ernährung zu sorgen. Dazu gehört im ersten Zuge auch mehr und bessere Information der Bürgerinnen und Bürger über Herz- und Kreislauf-Erkrankungen und die erhebliche Bedeutung der Risikofaktoren. Wir werden für ein stets gut erreichbares Angebot an Sport und Bewegung eintreten, zu einem verbesserten herzgesunden Speise- und Ernährungsangebot beitragen und über weitere Aktivitäten zu mehr Prävention und damit letztlich für mehr Herzgesundheit sorgen. Dabei denken wir selbstverständlich auch an die Probleme, die die gesunde Zukunft übergewichtiger Kinder in einem besonderen Maße gefährden",
                            "- Mehr über diese Herzproblematiken zu berichten und Hilfen anzubieten, heißt, eine Vielzahl kleiner und größerer Schritte zu gehen – auch dort, wo gemeinhin nicht so sehr an diese Problematiken gedacht wird. Die Medien werden berichten – die Westdeutsche Zeitung ist insoweit bereits mit mehreren viel beachteten Beiträgen dankenswerterweise vorangegangen. Wundern Sie sich also bitte nicht, wenn Sie in Sportvereinen und Gesundheitseinrichtungen, im Lebensmittelhandel, in der Gastronomie, in Bibliotheken, im Buchhandel – ja, sogar in der Tanzschule – auf das Thema Herzgesundheit stoßen. Sie wissen jetzt, dass die Wuppertaler Herzinitiative e.V.und damit auch Sie als unser geschätztes Mitglied dahinterstecken.",
                            "All unsere Aktivitäten stehen und fallen natürlich mit der Leistungsfähigkeit unseres Vereins. Halten Sie uns bitte als unser Mitglied die Treue und interessieren Sie Menschen in Ihrem persönlichen und beruflichen Umfeld für unsere gemeinsame Sache.",
                            "Gern gehen wir auch Ihren Hinweisen auf etwaige Förderer, Spender und Sponsoren nach. Unser neues Kernprojekt erfordert ja auch den Einsatz von finanziellen Mitteln.",
                            "Wir werden in absehbarer Zeit bei einer Mitgliederversammlung im Detail berichten. Dazu laden wir alsbald ein. Wenn Sie aktiv in die Herzgesundheitskampagne einbezogen werden wollen, ist das natürlich auch schon jetzt möglich.Setzen Sie sich bitte mit unserem Projektleiter, Klaus H.Richter, in Verbindung.",
                            "Ihnen und Ihren Angehörigen wünschen wir ein gesundes und in jeder Hinsicht gutes 2025.",
                            "Mit herzlichen Grüßen",
                            "Prof. Dr. Harmut Gülker - Vorsitzender des Vorstandes - Projektleiter",
                            "Rüdiger Neumann - Schatzmeister",
                            " Klaus H. Richter - Vorstandsbeauftragter"
                        ]
                    }
                    overview={"mitgliederbrief"}
                />
            </>
        );
    }
}

export default Mitgliederbrief;