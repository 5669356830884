import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

import ContactBar from './components/navigation/contact-bar';
import Navbar from './components/navigation/navbar';
import Footer from './components/footer/footer';

import Home from './pages/Home';
import About from './pages/About';
import MembershipContent from './components/membership/membership-content';
import DonationContent from './components/donation/donation-content';
import ImrpintContent from './components/imprint/imprint-content';
import DataProtection from './components/data-protection/data-protection';
import ScrollToTop from './components/scroller/scroll-to-top';
import BoardOfDirectors from './components/team/board-of-directors';
import Committees from './components/team/committees';
import Join from './components/join/join';
import Work from './components/work/work';
import Herzgesundheit from './components/work/articles/herzgesundheit';
import Herzrettung from './components/work/articles/herzrettung';
import NeueImpulse from './components/work/articles/neue-impulse';
import Goals from './components/goals/goals';
import FAQ from './components/faq/faq';
import Herzsachen from './components/work/articles/herzsachen';
import HerzenRetten from './components/work/articles/herzen-retten';
import NewWork from './components/work/newwork';
import InfosHerz from './components/work/articles/infos-herz';
import Gallery from './components/gallery/gallery';
import Mitgliederbrief from './components/work/articles/mitgliederbrief';

class App extends Component {
    render() {
        return (
            <React.Fragment>

                <ContactBar />
                <Navbar />

                <BrowserRouter>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/info' element={<About />} />
                        <Route path='/aus-unserer-arbeit-alt' element={<Work />} />
                        <Route path='/aus-unserer-arbeit-neu' element={<NewWork />} />

                        <Route path='/herzgesundheit' element={<Herzgesundheit />} />
                        <Route path='/herzrettung-etabliert' element={<Herzrettung />} />
                        <Route path='/neue-impulse' element={<NeueImpulse />} />
                        <Route path='/herzsachen' element={<Herzsachen />} />
                        <Route path='/herzen-retten' element={<HerzenRetten />} />
                        <Route path='/infos-herz' element={<InfosHerz />} />
                        <Route path='/mitgliederbrief' element={<Mitgliederbrief />} />

                        <Route path='/galerie' element={<Gallery />} />
                        <Route path='/aufgaben-und-ziele' element={<Goals />} />
                        <Route path='/vorstand' element={<BoardOfDirectors />} />
                        <Route path='gremien' element={<Committees />} />
                        <Route path='/mitgliedschaft' element={<MembershipContent />} />
                        <Route path='/spenden' element={<DonationContent />} />
                        <Route path='/faq' element={<FAQ />} />
                        <Route path='/impressum' element={<ImrpintContent />} />
                        <Route path='/datenschutz' element={<DataProtection />} />
                    </Routes>
                </BrowserRouter>

                <ScrollToTop />
                <Footer />
            </React.Fragment>
        );
    }
}

export default App; 