import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';

class NewWork extends Component {
    state = {  } 
    render() { 
        return (
            <section className="work">
                <Helmet>
                    <title>Neues aus 2024 - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie die neuesten Entwicklungen und Aktivitäten der Wuppertaler Herzinitiative e.V. im Jahr 2024. Bleiben Sie über die aktuellen Ereignisse und Initiativen informiert, die dazu beitragen, das Bewusstsein für Herzgesundheit zu fördern und Menschen in Wuppertal zu unterstützen." />
                </Helmet>


                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">
                                Aus unserer Arbeit
                            </h1>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">

                    <div className="container px-5">

                        <h2 className="fw-bolder fs-5 mb-4 text-center text-muted mb-5">
                            Neues aus 2024 und 2025
                        </h2>
                        
                        <div className="row gx-5 justify-content-center align-items-stretch"> {/* Entferne d-flex, da es nicht mehr benötigt wird */}
                            <div className="col-lg-3 mb-5">
                                <div className="card-custom shadow border-0 h-100">
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary rounded-pill mb-2">Beitrag</div>
                                        <div className="h5 card-title">Mitgliederbrief</div>

                                        <div className="d-flex align-items-center mb-1">
                                            <div className="small">
                                                <div className="text-muted">Freitag, 15.01.2025</div>
                                            </div>
                                        </div>

                                        <p className="card-text mb-0">
                                            Sehr geehrte Mitglieder der Wuppertaler Herzinitiative e.V.,
                                            zum neuen Jahr sagen wir Ihnen ein herzliches Dankeschön – Sie haben mit Ihrer Mitgliedschaft (...)
                                        </p>

                                        <a className="btn btn-primary btn-sm text-white mt-4" href="mitgliederbrief">
                                            Mehr lesen!
                                        </a>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-3 mb-5">
                                <div className="card-custom shadow border-0 h-100"> {/* Ändere die Klasse auf card-custom */}
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary rounded-pill mb-2">Beitrag</div>

                                        <div className="h5 card-title">Wir helfen Herzen retten</div>

                                        <div className="d-flex align-items-center mb-1">
                                            <div className="small">
                                                <div className="text-muted">Freitag, 12.04.2024</div>
                                            </div>
                                        </div>


                                        <p className="card-text mb-0">
                                            2022 haben wir das Smartphone-basierte Mobile Retter-Programm nach Wuppertal geholt, es organisiert und finanziert (...)
                                        </p>

                                        <a className="btn btn-primary btn-sm text-white mt-4" href="herzen-retten">
                                            Mehr lesen!
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 mb-5">
                                <div className="card-custom shadow border-0 h-100"> {/* Ändere die Klasse auf card-custom */}
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary rounded-pill mb-2">Beitrag</div>
    
                                        <div className="h5 card-title">Wir informieren rund ums Herz</div>

                                        <div className="d-flex align-items-center mb-1">
                                            <div className="small">
                                                <div className="text-muted">Donnerstag, 04.04.2024</div>
                                            </div>
                                        </div>

                                        <p className="card-text mb-0">
                                            Wissen ist ein gesundheitliches Wundermittel. Das gilt auch oder insbesondere für die gesundheitsrelevanten (...)
                                        </p>

                                        <a className="btn btn-primary btn-sm text-white mt-4" href="infos-herz">
                                            Mehr lesen!
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 mb-5">
                                <div className="card-custom shadow border-0 h-100"> {/* Ändere die Klasse auf card-custom */}
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary rounded-pill mb-2">Beitrag</div>
                                            <div className="h5 card-title">Wir navigieren für Ihre Herzgesundheit und Ihre gesundheitliche Versorgung</div>

                                            <div className="d-flex align-items-center mb-1">
                                                <div className="small">
                                                    <div className="text-muted">Freitag, 29.03.2024</div>
                                                </div>
                                            </div>

                                        <p className="card-text mb-0">
                                            Die Wuppertaler Herzinitiative e.V. setzt sich intensiv für die Herzgesundheit der Frauen (...)
                                        </p>

                                        <a className="btn btn-primary btn-sm text-white mt-4" href="herzsachen">
                                            Mehr lesen!
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="line text-center d-flex align-items-center justify-content-center mb-2" />

                        </div>
                    </div>

                    <div className="container d-flex justify-content-center align-items-center text-center mt-3">
                        <div className="alert alert-primary" role="alert">
                            <FontAwesomeIcon icon={faInfoCircle} /> Ausschnitte über unsere Arbeit aus den letzten <a href="aus-unserer-arbeit-alt" className="alert-link">Jahren</a>.
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default NewWork;